<template src="./Home3D.html"></template>

<script src="/Home3D/Build/UnityLoader.js"></script>

<script>
import ChangeHome from "../../components/ChangeHome/ChangeHome";
import Unity from "vue-unity-webgl";
import ArrobaMedellinLogo from "@/components/Icons/ArrobaMedellinLogo";
import ArrobaMedellinHeader from "@/components/ArrobaMedellinAppBar/ArrobaMedellinAppBar";
import { songs } from "@/utils/constants";
import ModalDataUpdate from "../../components/ModalDataUpdate/ModalDataUpdate";
import institutionService from "../../services/Institution";
import tutorial from "@/mixins/tutorial";
import { mapGetters } from "vuex";

export default {
  name: "home",
  mixins: [tutorial],
  components: {
    ArrobaMedellinLogo,
    ArrobaMedellinHeader,
    Unity,
    ModalDataUpdate,
    ChangeHome,
  },
  data() {
    return {
      userInfo: null,
      isMobile: false,
      heightBrowser: "640",
      isCardModalActive: false,
      openModalInvited: false,
      songs,
      openModalDataUpdate: false,
      role: "",
    };
  },
  destroyed() {
    location.reload();
  },
  computed: {
    ...mapGetters({
      muteState: "getMutPage",
    }),
  },
  watch: {
    muteState: function (value) {
      console.log("mute", value);
      this.$refs.myInstance.message("Bridge", "MuteAudio", value.toString());
    },
  },
  mounted() {
    this.heightBrowser = window.innerHeight - 125;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isMobile = true;
    }
    this.redirectToAdmin();
    this.role = this.$store.state.User.user.role.name;
    if (this.role === "Invited") {
      this.openModalInvited = true;
    } else {
      this.isCardModalActive = true;
    }
  },
  update() {
    this.heightBrowser = (window.outerHeight * 75) / 100;
  },
  methods: {
    redirectToAdmin() {
      const role = this.$store.getters.getUser.role.name;
      if (role === "Administrator") {
        this.$router.push("/AdminPanelAcademic");
      }
      if (role === "AdministratorCall") {
        this.$router.push("/administradorConvocatorias");
      }
    },
    redirectTo(route) {
      this.$router.push({ path: `${route}` });
    },
    closeModalDataUpdate() {
      this.openModalDataUpdate = false;
    },
    async sendDataToUpdate(infoUpdate) {
      try {
        for (let index = 0; index < infoUpdate.length; index++) {
          const element = infoUpdate[index];
          const obj = {
            usuario_id: this.$store.state.User.user.id,
            institucion_id: element,
          };
          await institutionService.universityApplication(obj);
        }
      } catch (error) {
        console.error(error);
      }
      this.openModalDataUpdate = false;
    },
    continueHome() {
      if (this.role === "Student" || this.role === "Teacher") {
        if (this.role === "Student") {
          this.dataTutorial("home3d");
        }
        if (!this.$store.state.User.user.institutionId) {
          this.openModalDataUpdate = true;
        }
      }
      if (this.role === "Invited") {
        this.dataTutorial("home3d");
      }
      this.isCardModalActive = false;
      this.openModalInvited = false;
    },
  },
};
</script>

<style lang="scss" scoped src="./Home3D.scss">
</style>
